<template>
  <router-view/>
</template>

<style lang="scss">
a{
	color: inherit;
	text-decoration: none;
}
.el-loading-mask{
	border-radius: 10px !important;
	backdrop-filter: blur(20px);
}
body{padding: 0;margin: 0;background-color: #E8EDF3;}
/* 进度条自定义颜色 */
#nprogress .bar {
  overflow: hidden;
  border-radius: 5px !important;
  height: 10px !important;
  top: 5px !important;
  box-shadow: rgba(121, 208, 255, 0.5) 5px 0 10px 1px;
  background: linear-gradient(to right,#213065,#008DF0) !important;
}
/* // 设置滚动条的样式 */
::-webkit-scrollbar {/*滚动条整体样式*/width: 5px;/*高宽分别对应横竖滚动条的尺寸*/height: 0px !important;background-color: rgba(255, 255, 255, 0) !important;}
::-webkit-scrollbar-thumb {/*滚动条里面小方块*/border-radius: 50px !important;background-color: #315D7F !important;}
::-webkit-scrollbar-thumb:hover {background-color: #488abc !important;}
::-webkit-scrollbar-thumb:active {background-color: #203e55 !important;}
</style>
