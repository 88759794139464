import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入qs
import qs from 'qs';

const app = createApp(App)
const SetOverallFunction = app.config.globalProperties
app.use(router).use(ElementPlus).mount('#app')

// 调试
setInterval(function() { check() }, 1000);
var check = function() {
    function doCheck(a) {
        if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
            (function() {}["constructor"]("debugger")())
        } else {
            (function() {}["constructor"]("debugger")())
        }
        doCheck(++a)
    }
    try { doCheck(0) } catch (err) {}
};
check();

// 引入axios
import axios from "axios";
axios.defaults.timeout = 200000;//请求最大时间
//配置post的请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset:utf-8;';

// 获取免费接口
SetOverallFunction.Freeapi_img = async function(url){
	let returninfo
	const mykey = 'znxTp6R8xe4vthGQc4kAVYrOA0'
	var keys = url+'?key='+mykey
	return keys;
}
SetOverallFunction.Freeapi_json = async function(url){
	let returninfo
	const mykey = 'znxTp6R8xe4vthGQc4kAVYrOA0'
	var keys = url+'?key='+mykey+'&type=json'
	await axios.get(keys).then((res)=>{
		returninfo = res
	}).catch((err)=>{
		returninfo = {
			state:'error',
			mesg:err
		}
	})
	return returninfo
}
SetOverallFunction.Api_SG = async function(url,cx,code){
	let returninfo
	const mykey = 'znxTp6R8xe4vthGQc4kAVYrOA0'
	var keys = url+'?key='+mykey+'&cx='+cx+'&code='+code
	if(!code){
		keys = url+'?key='+mykey+'&cx='+cx+'&code='+''
	}
	await axios.get(keys).then((res)=>{
		returninfo = res
	}).catch((err)=>{
		returninfo = {
			state:'error',
			mesg:err
		}
	})
	return returninfo
}
